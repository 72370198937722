
class Logs {
  constructor () {
    console.log('Log 日志类')
    this.time = 0
  }

  log (...value) {
    const development = process.env.NODE_ENV === 'development'
    if (!development) return
    const nowTime = new Date().getTime()
    this.time === 0 && (this.time = nowTime)
    console.warn('日志输出 => :', `时间 : ${nowTime - this.time} || `, ...value)
  }
}
let instance = null
const LogsInstance = () => {
  if (instance) return instance
  instance = new Logs()
  return instance
}
export default LogsInstance()
