/*
 * @Author: 伽蓝
 * @Date: 2020-07-30 11:33:14
 * @LastEditTime: 2020-12-02 14:47:23
 * @LastEditors: 伽蓝
 * @Description:
 * @FilePath: /h5-web-view/src/main.js
 * @代码不规范,调试两行泪
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Logs from 'tools/Logs'
import api from './api/index.js'// 导入api接口
import './style/index.styl'
import 'element-ui/lib/theme-chalk/index.css'
import Element from 'libs/Element' // 全局值存储
import './assets/icon/iconfont.css'
import './assets/icon/iconfont.js'

Vue.use(Element)

Vue.config.productionTip = false
Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.prototype.$log = Logs // 日志打印
Vue.prototype.$globle = {}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// const token = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbmV3YXBpLmhlc2lsaW5nLmNvbVwvYXBpXC9tYW5hZ2VcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNTg3NTE5ODc2LCJuYmYiOjE1ODc1MTk4NzYsImp0aSI6IldwME1yTHhhMWtHVnFSNzkiLCJzdWIiOjI5NDksInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEiLCJyb2xlIjoidXNlciJ9.tOtm-cZq9EldbEmsrUTGvbnFacR6yZqNT2NNnoZAgbY'
// localStorage.setItem('user_token', token)
