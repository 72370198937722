import axios from 'tools/Http' // 导入http中创建的axios实例
const Preview = {
// 预览 节目数据
  getContentData (content) {
    return axios.get(`v1/content/${content}/show`)
  },
  // 预览 模板数据
  getTemplateData (id) {
    return axios.get(`v1/template/${id}`)
  },
  // 预览 MP 模板数据
  getMpViewData ({ date, name }) {
    const url = `v1/h5/mp/preview?date=${date}&name=${name}`
    return axios.get(url)
  }

}
export default Preview
