import axios from 'tools/Http' // 导入http中创建的axios实例
const Marketing = {
// 预览 节目数据
  // eslint-disable-next-line camelcase
  getUsedMarket (content_id, token) {
    // eslint-disable-next-line camelcase
    return axios.get(`v8/marketMix/getUsedMarketNoAuth?content_id=${content_id}&token=${token}`)
  }
}
export default Marketing
