/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
// import router from '../router'
import { generateGUID } from 'tools/utils'
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, error) => {
  // 状态码判断
  switch (status) {
    case 301:
      console.error(error || '服务器错误')
      break
    case 400:
      console.error(error || '服务器错误')
      break
    // case 401:
    //   console.error('登录超时，请重新登录')
    //   localStorage.clear()
    //   setTimeout(() => {
    //     window.location.href = process.env.VUE_APP_M_LOGIN
    //   }, 300)
    //   break
    case 403:
      break
    case 404:
      console.error('接口地址错误')
      break
    case 405:
      console.error('请求方法错误')
      break
    case 429:
      console.error('操作太频繁')
      break
    case 500:
      console.error('服务器错误')
      break
    default:
      console.error('操作失败')
      break
  }
}

// 创建axios实例
const instance = axios.create({ timeout: 1000 * 12 })
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 设置 baseURL
instance.defaults.baseURL = process.env.VUE_APP_API
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */

instance.interceptors.request.use(
  config => {
    if (['post', 'put', 'patch'].indexOf(config.method) > -1) {
      config.data = JSON.stringify(config.data)
    }
    if (localStorage.getItem('socket_token')) {
      config.headers['Socket-Token'] = localStorage.getItem('socket_token')
    } else {
      const socketToken = generateGUID()
      localStorage.setItem('socket_token', socketToken)
      config.headers['Socket-Token'] = socketToken
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    if (localStorage.getItem('user_token')) {
      config.headers.Authorization = localStorage.getItem('user_token')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
  // 请求失败
  error => {
    const { response } = error
    if (response) {
      errorHandle(response.status, response.data.error)
    }
    return Promise.reject(response.data.error)
  })

export default instance
