var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "of-h vw-100 vh-100", attrs: { id: "app" } },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }