import { Loading, Message, MessageBox, Notification } from 'element-ui'
// install 在使用 use 时可以同步注册多个
const element = {
  install: function (Vue) {
    // Vue.use(Alert)
    Vue.prototype.$message = Message
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$notify = Notification
    Vue.use(Loading)
  }
}
export default element
